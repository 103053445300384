( function( $, window, document, undefined ) {
   
  var selected_tag = 'all',
  		whats_on_wrapper = $( '.js-whats-on-events' ),
  		loading_placeholder = $( '.events--loading' ),
  		paged = 2,
  		posts_per = $( '.posts_per' ).val(),
  		event_genre = $( '.event_genre' ).val(),
  		event_start_date = $( '.event_start_date' ).val(),
  		event_end_date = $( '.event_end_date' ).val(),
  		event_price = $( '.event_price' ).val(),
  		event_search = $( '.event_search' ).val(),
  		event_location = $( '.event_location' ).val();

  $.each( $( '.js-whatson-filter' ), function(i, left) {
		
		$( '.whatson-filter__tag' ).on( 'click touchstart', function(){

			$( '.whatson-filter__search' ).removeClass( 'active' );
			$( '.events__search' ).removeClass( 'active' );
			$( '.whatson-filter__tag' ).removeClass( 'active' );

			$( this ).toggleClass( 'active' );
      
	    selected_tag = $( this ).data( 'cat' );
			$( '.post_offset' ).val( 3 ); 
		  
      var post_offset = $( '.post_offset' ).val();

			$( '#load-more-events' ).css( 'display', 'block' );

			$.ajax({
	      url: my_ajax_object.ajax_url, 
	      type:'POST', 
	      data: {
          action: 'jh_filter_whats_on',
          tag: selected_tag,
          posts_per: posts_per,
          event_genre: event_genre,
          event_search: event_search,
          event_start_date: event_start_date,
          event_price: event_price,
          event_end_date: event_end_date,
          event_location: event_location
	      },
	      beforeSend: function (){
	        whats_on_wrapper.addClass( 'events-fade-out' );
	        loading_placeholder.addClass( 'fade-in' );
	      },
	      success: function (result) {
	      	whats_on_wrapper.removeClass( 'events-fade-out' )
	        loading_placeholder.removeClass( 'fade-in' );
    			whats_on_wrapper.empty();
    			
    			paged = 2;

      		whats_on_wrapper.addClass( 'events-fade-in' );
	        whats_on_wrapper.html(result);

	        setTimeout( function(){
        		whats_on_wrapper.removeClass( 'events-fade-in' );
	        }, 300 );

	    		$( '.filter_cat' ).val( selected_tag );
	      },

	      error: function(err){
	        console.log(err);
	      }

	  	});

		});

	});

  $( '#load-more-events' ).on( 'click touchstart', function(){

			$.ajax({	      
	      url: my_ajax_object.ajax_url, 
	      type:'POST', 
	      data: {
          action: 'jh_filter_whats_on',
          tag: selected_tag,
          posts_per: posts_per,
          paged: paged,
          event_genre: event_genre,
          event_search: event_search,
          event_start_date: event_start_date,
          event_price: event_price,
          event_end_date: event_end_date,
          event_location: event_location
	      },
	      beforeSend: function (){
	      	$( '#load-more-events' ).addClass( 'disabled' );
	      },
	      success: function (result) {
	      	$( '#load-more-events' ).removeClass( 'disabled' );
	        whats_on_wrapper.append(result);
	    		$( '.filter_cat' ).val( selected_tag );
  				var $el = $('.event--notification');
					if( $el.parent().length > 0 ){
						$( '#load-more-events' ).css( 'display', 'none' );
					};
	      },
	      error: function(err){
	        console.log(err);
	      }
	  	});
  		
  		paged++;
  });


  $( '.whatson-filter__search' ).on( 'click touchstart', function(){
    
    $( '.js-search-panel' ).addClass( 'search--active' );

    $( '.js-search-panel-close' ).on( 'touchstart click', function(){
      $( '.js-search-panel' ).removeClass( 'search--active' );
      $( '.whatson-filter__tag' ).removeClass( 'active' );
			$( '.events__search' ).removeClass( 'active' );
    });

	  $( '.whatson-filter__tag' ).removeClass( 'active' );
		$( this ).toggleClass( 'active' );

		$( '.events__search' ).addClass( 'active' );


  });

})( jQuery, window, document );
