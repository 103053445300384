( function( $, window, document, undefined ) {
	$( document ).ready(function() {

		var isValueSteppable = function( value, min, max, step, operator ) {
			var newValue = value;

			// Use multiple when no operator is provided.
			if ( typeof operator == "undefined" ) {
				operator = '+';
			}

			// If adding a step.
			if ( '+' == operator ) {
				newValue += step;

				if ( typeof max == "undefined" ) {
					return newValue;
				}
			}

			// If subtracting a step.
			if ( '-' == operator ) {
				newValue -= step;

				if ( typeof min == "undefined" ) {
					return newValue;
				}
			}

			if ( newValue > max || newValue < min ) {
				return false;
			} else {
				return newValue;
			}
	};

		$('.js-quantity-stepper').click( function(e) {
			var $target = $( '#' + $(this).attr('aria-controls') ),
					newValue = isValueSteppable(
						parseInt( $target.val() ),
						parseInt( $target.attr('min') ),
						parseInt( $target.attr('max') ),
						parseInt( $target.attr('step') ),
						$(this).attr('data-operator')
					);

			if ( parseInt(newValue) >= 0 ) {
				$target.val( newValue );
				$target.trigger('change');
			}

			e.preventDefault();
			return false;
		} );

	});

} )( jQuery, window, document );
