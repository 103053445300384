// Setup the Featherlight plugin
// This file is sectioned based on various functionality.
// If you don't need one of the parts get rid!

( function( $, window, document, undefined ) {
	
	if ( typeof $.featherlight !== 'undefined' ) {

// ~~~~~~~~~ ALLOW FORMS TO BE `CLONED` INTO FEATHERLIGHT CONTAINERS ~~~~~~~~~ 
		
		/**
		 * Add a suffix to an element's attribute.
		 * @param {jQuery} $elem    The element to modify.
		 * @param {string} attrName The name of the attribute to modify.
		 * @param {string} suffix   The suffix to add.
		 */
		function addAttrSuffix( $elem, attrName, suffix ) {
			if ( $elem.attr( attrName ) ) {
				$elem.attr( attrName, $elem.attr( attrName ) + suffix );
			}
		}

		/**
		 * Clone a featherlight container so that all IDs, and for attributes, remain unique.
		 * Other attributes, aria- & data- for example could be referencing an ID but there are so many it would be near impossible to change them all.
		 */
		function cloneFeatherlight( $target, variant ) {
			if ( $target.length ) {
				$target = $target.clone();

				$target.find('[for]').each( function() {
					addAttrSuffix( $(this), 'for', '-featherlight' );
				} );

				$target.find('[id]').each( function() {
					addAttrSuffix( $(this), 'id', '-featherlight' );
				} );

				$.featherlight( $target, { variant: variant } );
			}
		}
		// Expose as a property
		$.featherlight.cloneFeatherlight = cloneFeatherlight;
		
		/**
		 * Open a cloned version of a form in a Featherlight lightbox, rather than the actual form.
		 * This makes sure that IDs aren't repeated across the page, helping with targeting events, clicks on <label>'s etc.
		 * By default adds the '-featherlight' suffix to all 'for' and 'id' attributes.
		 */
		$('[data-featherlight-clone]').click( function( event ) {
			var variant = null;

			if ( $(this)[0].hasAttribute('data-featherlight-variant') ) {
				variant = $(this).attr('data-featherlight-variant');
			}

			cloneFeatherlight( $( $(this).attr('data-featherlight-clone') ), variant );

			event.preventDefault();
			return false;
		} );


// ~~~~~~~~~ STOP SCROLL ON THE BODY WHEN A FEATHERLIGHT CONTAINER IS OPEN ~~~~~~~~~ 

		var scroll_top = document.body.scrollTop,
				// Keep track of how many are open, for nested featherlights
				container_count_open = 0, 
				
				freezeScroll = function() {
					container_count_open++;

					if ( 1 == container_count_open ) {
						scroll_top = $(window).scrollTop();

						$('body').css( {
							position: 'fixed',
							top: - $(window).scrollTop() + 'px',
							overflowY: 'scroll',
						} );
					}
				},

				restoreScroll = function() {
					container_count_open--;

					$('body').css( {
						position: '',
						top: '',
						overflowY: '',
					} );
					document.documentElement.scrollTop = $(window).scrollTop();

				};

		function afterOpen( event ) {
			freezeScroll();
		}

		function beforeClose( event ) {
			restoreScroll();
		}

		$.featherlight.defaults.afterOpen = afterOpen;
		$.featherlight.defaults.beforeClose = beforeClose;

	}

} )( jQuery, window, document );
