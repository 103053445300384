// Scroll Animations

( function( $, window, document, undefined ) {

  $( document ).ready( function() {
    $( "#hero .testimonial" ).addClass( 'visible' );
    
    $( "#featured-galleries" ).addClass( 'visible' );
    
    if( $( '#event .panel__content' ).visible( true ) ){
      $( "#event .panel__inner" ).addClass( 'visible' );
      $( "#event .title--outline" ).addClass( 'neon-white' ); 
    }
  });

  $( window ).scroll( function() {
    var scrollAmount = $(window).scrollTop();

    if( $( '.panel--no-hero' ).visible( true ) ){
      $( ".panel--no-hero .title--outline" ).addClass( 'neon-white' ); 
    }

    if( $( '#whats-on .panel__content' ).visible( true ) ){
      $( "#whats-on .title--outline" ).addClass( 'neon-white' ); 
    }
    
    if( $( '#events' ).visible( true ) ){
      $( "#events" ).addClass( 'visible' );
    }
    
    if( $( '#galleries' ).visible( true ) ){
      $( "#galleries" ).addClass( 'visible' );
    }
    
    if( $( '#contact .panel__content' ).visible( true ) ){
      $( "#contact .panel__inner" ).addClass( 'visible' );
      $( "#contact .title--outline" ).addClass( 'neon-black' ); 
    }
    
    if( $( '#christmas-parties .panel__content' ).visible( true ) ){
      $( "#christmas-parties .panel__inner" ).addClass( 'visible' );
      $( "#christmas-parties .title--outline" ).addClass( 'neon-black' ); 
    }
    
    if( $( '#intro .panel__content' ).visible( true ) ){
      $( "#intro .panel__inner" ).addClass( 'visible' );
      $( "#intro .title--outline" ).addClass( 'neon-white' ); 
    }
    
    if( $( '#dining .panel__content' ).visible( true ) ){
      $( "#dining .panel__inner" ).addClass( 'visible' );
      $( "#dining .title--outline" ).addClass( 'neon-white' ); 
    }
    
    if( $( '.js-panel-image .panel__content' ).visible( true ) ){
      $( ".js-panel-image .panel__image" ).addClass( 'panel__image--skew' );
      $( ".js-panel-image .panel__inner" ).addClass( 'visible' );
      $( ".js-panel-image .title--outline" ).addClass( 'neon-white' ); 
    }
    
    if( $( '#event .panel__content' ).visible( true ) ){
      $( "#event .panel__inner" ).addClass( 'visible' );
      $( "#event .title--outline" ).addClass( 'neon-white' ); 
    }
    
    if( $( '#testimonials .panel__content' ).visible( true ) ){
      $( "#testimonials .testimonial" ).addClass( 'visible' );
    }
    
    if( $( '.footer__main' ).visible( true ) ){
      $( ".footer__main" ).addClass( 'visible' );
    }
    
    if( $( '#featured-galleries' ).visible( true ) ){
      $( "#featured-galleries" ).addClass( 'visible' );
    }
    
    if( $( '#upcoming-events' ).visible( true ) ){
      $( "#upcoming-events" ).addClass( 'visible' );
    }

  });

} )( jQuery, window, document );
