// Datepicker

( function( $, window, document, undefined ) {
	
  $( document ).ready( function() {
		$( ".js-datepicker" ).datepicker({
			dateFormat: "dd/mm/yy",
			firstDay: 1,
			minDate : 0,
      changeMonth: true,
      changeYear: true,
			beforeShowDay: function(date) {
        var day = date.getDay();
        return [(day != 0)];
	    }
		});

		$( ".js-datepicker-dob" ).datepicker({
			minDate: new Date( 1950, 1 - 1, 1 ), maxDate: '-18Y',
      dateFormat: 'dd/mm/yy',
      defaultDate: new Date( 2000, 1 - 1, 1 ),
      changeMonth: true,
      changeYear: true,
      yearRange: '-110:-18'
		});
  });
	
} )( jQuery, window, document );
