// Scroll Animations

( function( $, window, document, undefined ) {

  $( '#date-filter-search' ).change( function(){

    console.log( $( this ).val() );
    $( '#search-date_submit' )
  });

} )( jQuery, window, document );
