( function( $, window, document, undefined ) {

	$( document ).ready( function(){

		var anchor_width = ( $( '.anchor-section' ).width()/2 ) - 50;
		var anchor_height = $( '.anchor-section' ).width()/2;

		$( '.anchor-section' ).css( 'left', '-'+anchor_width+'px' );
		$( '.anchor-section' ).css( 'bottom', anchor_height+'px' );

	});
	
} )( jQuery, window, document );
 
