( function( $, window, document, undefined ) {
   
  var news_wrapper = $( '.js-news' ),
      n_paged = 2,
      n_post_offset = 8;


  $( '#js-lazyload-news' ).on( 'click touchstart', function(){

    $.ajax({        
      url: my_ajax_object.ajax_url, 
      type:'POST', 
      data: {
        action: 'jh_load_news',
        n_paged: n_paged,
        n_post_offset: n_post_offset
      },
      beforeSend: function (){
        
        $( '#js-lazyload-news' ).addClass( 'disabled' );

      },
      success: function (result) {
        
        $( '#js-lazyload-news' ).removeClass( 'disabled' );

        news_wrapper.append(result);
          var $el = $('.event--notification');
          if( $el.parent().length > 0 ){
            $( '#js-lazyload-news' ).css( 'display', 'none' );
          }; 
      },
      error: function(err){
        console.log(err);
      }
    });

    n_paged++;
    n_post_offset += 2;
  });

})( jQuery, window, document );
