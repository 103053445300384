// Global events and functions

(function ($, window, document, undefined) {

	$('a[href^="#"]').click(function (event) {

		var $target = $(event.target);

		if (!$target.is('a')) {
			$target = $target.closest('a');
		}

		var href = $target.attr('href');

		if ('#' !== href && $(href).length) {
			window.SmoothScroll.toElement($(href));
		}

		// Do not prevent default so that hash is added to browser URL
	});

	$( ".js-location-toggle" ).on( 'touchstart click', function( e ){
		$( this ).siblings( ".header__locations--mobile" ).slideToggle( 400 );

	 	e.preventDefault();
	 	return false;
	});

	$( ".js-accordion-toggle" ).on( 'touchstart click', function( e ){
		$( this ).siblings( ".accordion__content" ).slideToggle( 400 );

	 	e.preventDefault();
	 	return false;
	});

	$( '.js-dmn-toggle' ).on( 'click touchstart', function( e ){
    $( 'body' ).addClass( 'fixed' );
    $( '.dmn-form' ).addClass( 'active' );

    e.stopPropagation();
  });

	function close() {
    $( ".dmn-form" ).append( '<div class="dmn-close js-dmn-close">x</div>' );
	};
	$( document ).ready( function () {
	    window.setTimeout( close, 300 );
	});

	$( 'body' ).on( 'click touchstart', '.js-dmn-close', function(){
    $( 'body' ).removeClass( 'fixed' );
    $( '.dmn-form' ).removeClass( 'active' );
	});

	$( document ).ready( function(){
	  $( 'form.checkout' ).find( "#order_comments" ).each( function( ev ){
      if( !$( this ).val() ){
	     	$( this ).attr( "placeholder", "Notes about your order..." );
	  	}
	  });
	});

} )( jQuery, window, document );
