function bannerPageLoad(){
  $( '.content--hero' ).addClass( 'content--hero--visible' );
}

function bannerPageScroll(){
  $(".content--hero").css({
    'margin-top': 0 - (($(this).scrollTop()) * 0.2),
    'transition': '0s',
    'transition-delay': '0s' 
  });
  
  $(".slider--arrows").css({
    'opacity': 1 - (($(this).scrollTop()) / 200)
  });
}

function bannerPageTop(){
  $(".content--hero").css({
    'margin-top': 0,
    // 'transition': '.4s',
    // '-webkit-transition': '.4s',
    // 'transition-delay': '.1s',
    // '-webkit-transition-delay': '.1s' 
  });
  
  $(".slider--arrows").css({
    'opacity': 1
  });
}

$( document ).ready( function(){
  var lastScrollTop = 0;

  $( window ).scroll( function(){
    var st = $(window).scrollTop();

    if (st > 2 && st > lastScrollTop ){
      bannerPageScroll();
    } else {
      bannerPageTop();
    }
     
    lastScrollTop = st;
	});
  
  bannerPageLoad();

  $('.banner-slider').on('init', function( event, slick, currentSlide, nextSlide ){
    $('.slick-active' ).addClass( 'slick-anim' );
    $('.slick-active' ).addClass( 'slick-anim' );
  });

  $('.banner-slider').on('beforeChange', function( event, slick, currentSlide, nextSlide ){
    $('.slick-active').removeClass('slick-anim');
  });   

  $('.banner-slider').on( 'afterChange', function( event, slick, currentSlide, nextSlide ){ 
    $('.slick-active').addClass('slick-anim');
  });      
  
});
 
