( function( $, window, document, undefined ) {
   
  var gallery_wrapper = $( '.js-galleries' ),
      g_paged = 2,
      g_post_offset = 12,
      g_location = $( '#gallery_location' ).val();


  $( '#js-lazyload-galleries' ).on( 'click touchstart', function(){

    $.ajax({        
      url: my_ajax_object.ajax_url, 
      type:'POST', 
      data: {
        action: 'jh_filter_gallery',
        g_paged: g_paged,
        g_post_offset: g_post_offset, 
        g_location: g_location
      },
      beforeSend: function (){
        $( '#load-more-gallery' ).addClass( 'disabled' );
      },
      success: function (result) {
        $( '#load-more-gallery' ).removeClass( 'disabled' );
        gallery_wrapper.append(result);
          var $el = $('.event--notification');
          if( $el.parent().length > 0 ){
            $( '#js-lazyload-galleries' ).css( 'display', 'none' );
          };
      },
      error: function(err){
        console.log(err);
      }
    });

    g_paged++;
    g_post_offset += 4;
  });

})( jQuery, window, document );
