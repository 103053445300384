'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

// Smoothly scroll to anchor links with specific duration.

(function ($, window, document, undefined) {

	var SmoothScroll = function () {
		/**
   * Setup the class and properites.
   * @param  {Number} duration  The duration of the scroll, in ms.
   * @param  {Number} offset 		The amount, in pixels, to offset the final position.
   *                           	Negative values will stop before the y specified,
   *                         	  Positive values will stop after the y specified.
   */
		function SmoothScroll() {
			var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 400;
			var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

			_classCallCheck(this, SmoothScroll);

			var _ = this;

			_.$root = $('html, body');
			_.duration = duration;
			_.offset = offset;
		}

		/**
   * Scroll to a specific element.
   * @param  {jQuery|String} $element The element to scroll to.
   *                                  Accepts a jQuery element or a selector string.
   * @return {self}
   */


		_createClass(SmoothScroll, [{
			key: 'toElement',
			value: function toElement($element) {
				var _ = this;

				$element = $($element);

				if ($element.length) {
					_._scroll($element.offset().top);
				}

				return _;
			}

			/**
    * Scroll to a position on the screen.
    * @param  {Number} y The y coordinate to scroll to.
    * @return {self}
    */

		}, {
			key: 'toPosition',
			value: function toPosition(y) {
				var _ = this;

				_._scroll(y);

				return _;
			}

			/**
    * Animate the current scroll position to a y coordinate.
    * The final scroll position will be offset by the class offset property.
    * @param  {Number} y The y coordinate to scroll to.
    * @return {self}
    */

		}, {
			key: '_scroll',
			value: function _scroll(y) {
				var _ = this;

				_.$root.animate({
					scrollTop: y + _.offset
				}, {
					duration: _.duration,
					/**
      * A completion function triggered when the scroll finishes.
      * Use `$('html').on('complete.SmoothScroll')` to listen for it.
      * @param {Event} event The event object triggered.
      */
					complete: debounce(function () {
						_.$root.eq(0).trigger('complete.SmoothScroll');
					}, 50)
				});

				return _;
			}

			/**
    * Set a new offset for method calls to this class.
    * The current offset will be stored on the class so that
    * it can be restored later on.
    * @param {Number} offset The amount of pixels to offset the final scroll position.
    *                        Negative values will stop before the top of the element,
    *                        positive values will stop after the top of the element.
    */

		}, {
			key: 'setTempOffset',
			value: function setTempOffset(offset) {
				var _ = this;

				// Set private property offset to current class property
				// Can be restored later
				_._offset = _.offset;
				// Set class property to passed offset
				_.offset = offset;

				return _;
			}

			/**
    * Restore the offset applied earlier by setTempOffset().
    * @return {self}
    */

		}, {
			key: 'restoreOffset',
			value: function restoreOffset() {
				var _ = this;

				// Check for existence of temp property
				if (null !== _._offset && undefined !== _._offset) {
					// Set class property to temp property
					_.offset = _._offset;
					// Unset temp property
					_._offset = null;
				}

				return _;
			}
		}]);

		return SmoothScroll;
	}();

	// Expose API
	window.SmoothScroll = new SmoothScroll(400, 0);
})(jQuery, window, document);
