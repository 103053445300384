// Scroll Animations

( function( $, window, document, undefined ) {

  var header = $( '.header' );

  $( document ).ready( function( e ){
    var scrollAmount = $( window ).scrollTop();

    if( scrollAmount > 100  ){
      header.addClass( 'header--reduced' );
    } else {
      header.removeClass( 'header--reduced' );
    };

    if( scrollAmount > 140  ){
      header.addClass( 'header--add-bg' );
    } else {
      header.removeClass( 'header--add-bg' );
    };
    
  });

  $( document ) .scroll( function( e ){
    var scrollAmount = $( window ).scrollTop();

    if( scrollAmount > 100  ){
      header.addClass( 'header--reduced' );
    } else {
      header.removeClass( 'header--reduced' );
    };

    if( scrollAmount > 140  ){
      header.addClass( 'header--add-bg' );
    } else {
      header.removeClass( 'header--add-bg' );
    };
    
  });

} )( jQuery, window, document );
