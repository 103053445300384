// Global events and functions

( function( $, window, document, undefined ) {

  var header = $( '.header' );
	var banner_content = $( '.content--hero' );
	var nav_wrapper = $( '.nav-wrapper' );
	var nav_items = $( 'nav ul' );

	$( '.js-menu' ).on( 'click touchstart', function() {
		header.addClass( 'header--hide' );
		banner_content.addClass( 'content--hero--hide' );
		nav_wrapper.addClass( 'active' );
		nav_wrapper.addClass( 'fade-out' );

		setTimeout( function(){
			nav_items.addClass( 'nav-active' );
		}, 250 );

	});

	$( '.js-menu-close' ).on( 'click touchstart', function(){
		nav_items.removeClass( 'nav-active' );

		setTimeout( function(){
			nav_wrapper.removeClass( 'active' );
		}, 450 );

		setTimeout( function(){
			nav_wrapper.removeClass( 'fade-out' );
		}, 750 );

		setTimeout( function(){
			header.removeClass( 'header--hide' );
			banner_content.removeClass( 'content--hero--hide' );
		}, 600 );
	});

	$( '.nav ul li a' ).on( 'click touchstart', function(){
		nav_items.removeClass( 'nav-active' );

		setTimeout( function(){
			nav_wrapper.removeClass( 'active' );
		}, 450 );

		setTimeout( function(){
			nav_wrapper.removeClass( 'fade-out' );
		}, 750 );

		setTimeout( function(){
			header.removeClass( 'header--hide' );
			banner_content.removeClass( 'content--hero--hide' );
		}, 600 );
	});
	
} )( jQuery, window, document );
