/**
 * Stop scroll on the body element when a Featherlight container is open.
 * Modifies Featherlight's global defaults to add behaviour when Featherlight is opened and closed.
 * Wrapped in an anonymous function to avoid global var/function creation, and when Jquery.noConflict() is defined.
 */

( function( $, undefined ) {

	// if ( typeof $.featherlight !== 'undefined' ) {
	// 	var scroll_top = document.body.scrollTop,
	// 			container_count_open = 0, // To account for nested featherlights, keep track of how many open
				
	// 			freeze_scroll = function() {
	// 				container_count_open++;

	// 				if ( 1 === container_count_open ) {
	// 					if ( scrolljack && scrolljack.isInitialised() ) {
	// 						scrolljack.getContainer().scrolljack('disableTransitions');
	// 					}

	// 					scroll_top = document.body.scrollTop;

	// 					$('body').css( {
	// 						'position': 'fixed',
	// 						'top': -(scroll_top) + 'px'
	// 					} );
	// 				}
	// 			},

	// 			restore_scroll = function() {
	// 				container_count_open--;

	// 				if ( 0 === container_count_open ) {
	// 					if ( scrolljack && scrolljack.isInitialised() ) {
	// 						scrolljack.getContainer().scrolljack('enableTransitions');
	// 					}

	// 					$('body').css( {
	// 						'position': 'static',
	// 						'top': ''
	// 					} );
	// 					document.body.scrollTop = scroll_top;
	// 				}
	// 			};

	// 	$.featherlight.defaults.afterOpen = freeze_scroll;
	// 	$.featherlight.defaults.beforeClose = restore_scroll;
	// }

	/**
	 * Add a suffix to an element's attribute. Modifies the element directly.
	 * @param {jQuery} $elem    The element to modify.
	 * @param {string} attrName The name of the attribute to modify.
	 * @param {string} suffix   The suffix to add.
	 */
	function addAttrSuffix( $elem, attrName, suffix ) {
		if ( $elem.attr( attrName ) ) {
			$elem.attr( attrName, $elem.attr( attrName ) + suffix );
		}
	}

	/**
	 * Create a clone of an element, changing any IDs inside it so that no duplicates are created.
	 * @param {jQuery} $target The element to open in a lightbox.
	 */
	function cloneIntoLightbox( $target ) {
		// Secondary check of the length just to make sure
		if ( $target.length > 0 ) {
			$target = $target.clone();

			$target.find('label').each( function() {
				addAttrSuffix( $(this), 'for', '-fl' );
			} );

			$target.find('*').each( function() {
				addAttrSuffix( $(this), 'id', '-fl' );
			} );
		}

		$.featherlight( $target );
	}

	$(document).on( 'pageLoaded', function() {
		if ( '' !== fl_target && $(fl_target).length > 0 ) {
			cloneIntoLightbox( $(fl_target) );
		}
	} );
	
	$(document).on( 'click', '.js-fl-clone-form', function(e) {
		var $target = $( $(this).attr('data-fl-target') );

		if ( $target.length > 0 ) {
			cloneIntoLightbox( $target );
		}

		e.preventDefault();
		return false;
	} );

} )( jQuery );
